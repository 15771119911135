<template>
  <div>
    <section-hero class="pt-14" />
    <section-benefits
      id="benefits"
      class="mt-8 sm:mt-16"
    />
    <section-features
      id="features"
      class="mt-32 sm:mt-56"
    />
    <section-steps
      id="steps"
      class="mt-32 sm:mt-56"
    />
    <section-cases
      id="cases"
      class="mt-32 sm:mt-56"
    />
    <section-pricing
      id="pricing"
      class="mt-32 sm:mt-56"
    />
  </div>
</template>

<script setup lang="ts">
useSeoMeta({
  title: () => `${appName} - Diaporama pour vos écrans`,
  description: () => appDescriptionShort
})
</script>
