<template>
  <div class="relative mx-auto max-w-7xl px-6 lg:px-8">
    <section-title
      v-bind="titleProps"
      class="mx-auto max-w-2xl lg:text-center"
    />

    <div
      aria-hidden="true"
      class="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
    >
      <shape-contrail class="w-[72.1875rem]" />
    </div>

    <div class="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 lg:mt-24 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
      <div
        v-for="(tier, tierIndex) in tiers"
        :key="tier.name"
        :class="getClassList(tier.featured, tierIndex)"
        class="rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10"
      >
        <h3 class="text-base/7 font-semibold text-red-600">
          {{ tier.name }}
        </h3>

        <p class="mt-4 flex items-baseline gap-x-2">
          <span class="text-5xl font-semibold tracking-tight text-gray-900">
            Fr. {{ tier.priceMonthly }}.-
          </span>

          <span class="text-base text-gray-500">
            <span class="hidden sm:inline">
              par mois, par écran
            </span>

            <span class="inline sm:hidden">
              /mois /écran
            </span>
          </span>
        </p>

        <p class="mt-6 text-base/7 text-gray-600">
          {{ tier.description }}
        </p>

        <ul
          role="list"
          class="mt-8 space-y-3 text-sm/6 text-gray-600 sm:mt-10"
        >
          <li
            v-for="feature in tier.features"
            :key="feature"
            class="flex gap-x-3"
          >
            <icon
              name="ph:check-fat-duotone"
              aria-hidden="true"
              class="h-6 w-5 flex-none text-red-600"
            />

            {{ feature }}
          </li>
        </ul>

        <p class="mt-8 text-sm/6 text-gray-600 italic sm:mt-10">
          * {{ tier.details }}
        </p>

        <button-primary
          :outline="!tier.featured"
          label="Essayer gratuitement"
          link="/contact"
          class="mt-8 text-center block sm:mt-10"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SectionTitleProps } from 'types/section'

const titleProps: SectionTitleProps = {
  kicker: 'Prix par écran',
  title: 'Captivez vos clients à petit prix',
  description: `${appName} est décliné en deux formules adaptées à vos besoins. La formule "Pro" a tout le nécessaire pour bien démarrer. La formule "Entreprise" a plus de possibilités pour communiquer efficacement à vos clients.`
}

const tiers = [
  {
    featured: true,
    priceMonthly: 8,
    name: 'Pro',
    description: '', // TODO: Add description
    features: [
      '1 écran ou plus',
      'Diaporamas illimités',
      'Éditeur de contenu *',
      'Plusieurs personnes',
      'Support par email'
    ],
    details: 'Utilisation de YouTube et Vimeo pour les vidéos. Affichage des photos avec un lien (url).'
  },
  {
    featured: false,
    priceMonthly: 14,
    name: 'Entreprise',
    description: '', // TODO: Add description
    features: [
      'Fonctionnalités "Pro"',
      'Éditeur de contenu avancé *',
      'Modèles et thèmes',
      'Support prioritaire par email'
    ],
    details: `Stockage des vidéos et photos sur ${appName}. Utilisation de contenu avancé (météo, maps, etc.).`
  }
]

function getClassList (featured: boolean, index: number): string {
  if (featured) {
    return 'relative bg-white shadow-2xl'
  }

  const isFirstTier = index === 0
  const classList = isFirstTier
    ? 'rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none'
    : 'sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl'

  return `bg-white/70 sm:mx-8 lg:mx-0 ${classList}`
}
</script>
